.footer {
    a {
        text-decoration: none;
        color: #000000;
    }

    #info {
        background-color: #F7E400;
        display: flex;
        justify-content: space-between;
        padding: 1rem 4rem;

        #left {
            text-align: left;
        }

        #right {
            text-align: right;
        }
    }

    #copyright {
        line-height: 50px;
        background-color: #000000;
        color: #ffffff;
        font-family: 'openSans-Regular';
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    #info {
        flex-direction: column;
        padding: 1rem 2rem !important;
    }

    .block {
        align-items: flex-start !important;
    }

    #copyright {
        line-height: 30px !important;
        padding: 1rem 2rem !important;
    }
}

@font-face {
    font-family: 'openSans-Regular';
    src: url('../../assets/OpenSans-Regular.ttf');
}