@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@-webkit-keyframes bgAnim {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}
@-moz-keyframes bgAnim {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}
@keyframes bgAnim {
  0%{background-position:0% 11%}
  50%{background-position:100% 90%}
  100%{background-position:0% 11%}
}

::selection {
  background: rgba(0,77,232,1);
  color: #fff;
}

html {
  background-color: #000;
}

body {
  font-family: 'Inter', sans-serif;
  background: linear-gradient(270deg, rgba(130,0,232,1) 0%, rgba(0,77,232,1) 100%);
  /* background-size: 300% 300%;
  -webkit-animation: bgAnim 10s ease infinite;
  -moz-animation: bgAnim 10s ease infinite;
  animation: bgAnim 10s ease infinite; */
}

.body {
  min-height: 88vh !important;
}

.btn-primary,
.btn-secondary {
  border-radius: 24px;
  border-width: 2px;
  border-color: transparent;
  padding: 5px 20px;
  transition: all .3s ease;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-primary {
  background: linear-gradient(270deg, rgba(130,0,232,1) 0%, rgba(0,77,232,1) 100%);
}
.btn-primary:hover {
  background: linear-gradient(270deg, rgb(0, 0, 0) 0%, rgb(21, 21, 21) 100%);
}

.btn-secondary {
  background: linear-gradient(270deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
}
.btn-secondary:hover {
  background: linear-gradient(270deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
  border: 2px solid #000;
  color: #000;
}

.btn-primary svg,
.btn-secondary svg {
  margin-left: 1.5rem;
  transition: margin-left .3s ease;
  font-size: 30px;
}
.btn-primary:hover svg,
.btn-secondary:hover svg {
  margin-left: 2rem;
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-decoration: none;
  font-weight: 600;
  border-radius: 16px;
  border: 1px solid rgba(0,77,232,1);
  padding: 0 16px;
}
.btn-link svg {
  margin-left: .4rem;
}
.btn-link:hover {
  background: linear-gradient(270deg, rgba(130,0,232,1) 0%, rgba(0,77,232,1) 100%);
  color: #fff;
}

.row .col-lg-4,
.row .col-lg-8 {
  display: flex;
}

@media screen and (max-width: 991px) {
  .m-mt-4 {
    margin-top: 1.5rem;
  }
  .m-flex-column {
    flex-direction: column !important;
  }
}