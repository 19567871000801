h1 {
    text-transform: uppercase;
    font-family: 'openSans-ExtraBold';
    font-size: 70px;
    line-height: 60px;
    margin-block-start: 0.2em;
    margin-block-end: 0;
}

@media screen and (min-width: 1200px) {
    h1 {
        white-space: pre-line;
    }
}

@media screen and (max-width: 991px) {
    h1 {
        font-size: 34px;
        line-height: 30px;
    }
}

@font-face {
    font-family: 'openSans-ExtraBold';
    src: url('../../../assets/OpenSans-ExtraBold.ttf');
}