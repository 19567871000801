.production-details {
    .poster {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 16px;
        aspect-ratio: 16/9;
    }

    li {
        list-style-type: none;
    }

    ul {
        padding-inline-start: 0;
        margin-bottom: 0;
    }

    .video {
        background-color: #000000;
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
    
          div {
            height: 100% !important;
          }
        }
    }
    .column-2 {
        column-count: 2;
    }
}

@media only screen and (max-width: 991px) {
    .production-details {
        .poster-grid {
            margin-top: 1.5rem;
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            height: auto;
        
            .poster {
                width: 100%;
                aspect-ratio: 16/9;
            }
        }
    
        .description {
            max-width: 100%;
        }
    
        .staff {
    
            p {
                margin: 0 2rem;
            }
        }

        p {
            font-size: 14px;
        }
    
        .button {
            margin-top: 1rem;
            font-size: 11px;
        }

        .video {
            margin: 0 4rem;
        }
    }
}