.home {
    .block-lead {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.block-team {
    width: 100%;
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            width: 100%;
            aspect-ratio: 6/4;
            .swiper-slide {
                width: 100%;
                height: auto !important;
                background-position: top center !important;
                background-size: cover !important;
                border-radius: 16px;
            }
        }
    }
}

.showreel {
    background-color: #000000;
    position: relative;
    // padding-bottom: 33.25%;
    padding-top: 0;
    height: 0;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1500px;
    padding-bottom: 56.25%;
    
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      div {
        height: 100% !important;
      }
    }
}

.slider {
    .swiper {
        height: 380px;
    
        .swiper-slide {
            height: auto !important;
            background-position: center center !important;
            background-size: cover !important;
            border-radius: 16px;
        }
    }
}


.mb-2 {
    margin-bottom: 2rem;
}

@media only screen and (max-width: 991px) {
    #hero {
        height: auto;
        #hero-text {
            top: calc(50vh - 114px);
            margin-top: 6rem;
            font-size: 19px;
    
            h2 {
                font-size: 20px !important;
            }

            .text {
                max-width: 300px;
            }
        } 
    
        
    }

    #productions {
        .video {
            margin: 0;
        }

        h2 {
            margin-bottom: 1rem !important;
            margin-top: 1rem;
        }
    }
    
    .swiper {
        height: 220px;
    
        .swiper-slide {
            height: auto !important;
            min-width: auto !important;
            background-position: center center !important;
            background-size: cover !important;
        }
    }
    
    .slider {
        margin: 0;
        margin-bottom: 1rem;
    }
}
  