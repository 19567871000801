.h2 {
    font-family: 'openSans-ExtraBold';
    font-size: 22px;
    text-transform: uppercase;
    margin-block-start: 0;
    margin-block-end: 0;
    &.lead {
        font-size: 32px;
    }

}
@font-face {
    font-family: 'openSans-Bold';
    src: url('../../../assets/OpenSans-Bold.ttf');
}