.team-member {
    .about-me {
        img {
            border-radius: 16px;
            width: 100%;
            height: auto;
        }
        .description,
        a.btn-link {
            margin-left: 20px;
        }
        .btn-link {
            width: fit-content;
        }
    }
}

.mb-2 {
    margin-bottom: 2rem !important;
}

@media only screen and (max-width: 991px) {
    .team-member {
        .about-me {
            .description {
                margin-left: 0;
                margin-top: 1rem;
            }
            a.btn-link {
                margin-left: 0;
            }
        }
    
        .video {
            margin: 0;
            width: 100%;
        }
        
        iframe {
            width: 100%;
            height: auto;
        }
    }
}
