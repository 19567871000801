.image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: stretch;
    gap: 20px;
    text-decoration: none;
    margin-top: 24px;

    a {
        text-decoration: none;
        background-color: #fff;
        border-radius: 16px;
        padding: 1rem;

        .grid-poster {
            height: 100%;
        }

        .poster {
            border-radius: 8px;
            background-size: cover;
            width: 100%;
            aspect-ratio: 16/9;
        }

        h2 {
            color: #000000;
            text-align: left;
            margin-top: .6rem;
        }
    }
}

@media only screen and (max-width: 991px) {
    .image-grid {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        a {
            div {
                background-position: center;
            }
        }
    }
}