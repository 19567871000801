p {
    margin-bottom: 0;
    &.bold {
        font-weight: 900;
    }
}

.btn-primary,
.btn-secondary {
    p {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600; 
    }
}