.link {
    text-decoration: none;
    color: #000000;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;

    img {
        margin-left: 1.5rem;
        vertical-align: middle;
    }
}

@font-face {
    font-family: 'openSans-SemiBold';
    src: url('../../../assets/OpenSans-SemiBold.ttf');
}