.csapat {
    width: 100%;
    .portrait-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-items: center;
        margin: 3rem 2rem 0 2rem;

        &:last-child{
            margin-bottom: 4rem;
        }
    }
}

.leader-wrapper {
    .member {
        display: none;
    }
}

.member-wrapper {
    .leader {
        display: none;
    }
}


@media only screen and (max-width: 991px) {
    .csapat {
        .portrait-grid {
            display: grid;
            grid-template-columns: 1fr;
            justify-items: center;
            margin: 3rem 2rem 0 2rem;
    
            &:last-child{
                margin-bottom: 4rem;
            }
        }
    
    }
}