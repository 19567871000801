.project-card {
    margin-top: 1.5rem;
    .col-6 {
        display: flex;
    }
    .poster {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 12px;
        aspect-ratio: 16/9;
    }
    h3 {
        align-content: center;
        font-weight: 800;
    }
}

@media only screen and (max-width: 991px) {
    .project-card {
        margin: 2rem 0;
        display: flex;
        flex-direction: column;
        height: auto;
    
        .poster {
            display: block;
            border-radius: 4px;
            height: 200px;
            width: 100%;
        }
        
        .text {
            text-align: left;
            padding-bottom: .5rem;
            width: 100%;

            h3 {
                font-size: 24px;
                margin-top: 1rem;
            }
    
            p {
                font-size: 12px;
                margin: 2rem 0;
                width: 100%;
                margin-block-start: .5rem;
                margin-block-end: .5rem;
            }
        }
    }
}