.images {
    display: flex;
    flex-direction: column;

    .image {
        background-size: cover;
        width: 100%;
        &:first-child {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }
        &:last-child {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .images {
        .image {
            height: 180px;
        }
    }
    
}