.contact-card {
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    padding: 16px;
    margin-top: 20px;
    &:hover{
        .btn-link {
            background: linear-gradient(270deg, rgba(130,0,232,1) 0%, rgba(0,77,232,1) 100%);
            color: #fff;
        }
    }
    a {
        text-decoration: none;
        color: #000000;
    }

    .poster {
        background-size: cover;
        width: 100%;
        aspect-ratio: 9/11;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 8px;
    }

    .text {
        margin-top: 1rem;
        text-align: left;
        margin-bottom: 1rem;

        h4 {
            text-transform: uppercase;
        }

        .btn-link {
            padding: 4px 16px;
            width: fit-content;
        }

        .btn-link + p {
            margin-top: 4px;
            font-size: 16px;
            margin-left: 8px;
        }
    }
}

@media only screen and (max-width: 991px) {
    .contact-card {
        margin: 0;
    }
    .member {
        margin-top: 1.5rem;
    }
}