.section-title {
    background-color: #F7E400;
    margin: 2rem 4rem;
    height: 100px;
    border-radius: 4px;

    h1 {
        font-size: 36px;
        line-height: 100px;
    }
}

@media only screen and (max-width: 991px) {
    .section-title {
        margin: 2rem 2rem;
        height: auto;

        h1 {
            font-size: 24px;
            line-height: 2;
        }
    }
}