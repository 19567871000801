.video-slider {
    .swiper {
        border-radius: 16px;
        background-color: #000000;
        height: auto;
    }

    .swiper-slider {
        height: auto;
    }
    .swiper-button-next {
        background-image: url("../../assets/arrow-right-solid.svg");
        background-repeat: no-repeat;
        color: rgba(0,77,232,1);

        &:after {
            content: none !important;
        }
    }

    .swiper-button-prev {
        background-image: url("../../assets/arrow-left-solid.svg");
        background-repeat: no-repeat;
        color: rgba(0,77,232,1);

        &:after {
            content: none !important;
        }
    }

    .video {
        background-color: #000000;
        position: relative;
        padding-bottom: 44.25%;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
    
          div {
            height: 100% !important;
          }
        }
    }
}

@media only screen and (max-width: 991px) {
    .video-slider {
        .video {
            margin: 0 2rem;
        }
    }
}