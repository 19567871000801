.contact {
    .map {
        border-radius: 16px;
        margin-top: 24px;
    }
    
    p, a {
        color: #fff;
        text-decoration: none;
    }
    h3 {
        font-weight: 700;
        font-size: 21px;
    }
}

@media only screen and (max-width: 991px) {    
    .text-zone {
        margin: 3rem 2rem;
    
        p {
            font-size: 14px;
        }
    }
}
