body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block {
  background: #ffffff;
  border-radius: 16px;
  padding: 8px 16px;
}
.block-black {
  background: #000;
  color: #fff;
}
.block-title {
  background: #fff;
  padding: 16px 24px 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: fit-content;
}
.block-title + .block {
  border-top-left-radius: 0;
}

.block-site-title {
  color: #fff;
  background-color: transparent;
  padding: 16px 0;
}

.block-site-title h1 {
  font-size: 50px;
}
@media screen and (max-width: 991px) {
  .block-site-title h1 {
    font-size: 34px;
  } 
}

.border-16 {
  border-radius: 16px;
}