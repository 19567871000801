h5 {
    line-height: 24px;
    font-size: 16px;
    margin-block-start: 0;
    margin-block-end: 0;
    font-weight: normal;
    
    &.bold {
        font-family: 'openSans-Bold';
    }
}


@font-face {
    font-family: 'openSans-Bold';
    src: url('../../../assets/OpenSans-Bold.ttf');
}