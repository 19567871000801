.title {
    background-color: #000000;

    h1 {
        color: #ffffff;
        padding: 8rem;
        padding-bottom: 2rem;
        margin-top: 0;
    }
}

@media only screen and (max-width: 991px) {
    .title {
        height: auto;
    
        h1 {
            padding: 8rem 2rem 3rem;
        }
    }
}