.header {
    .block {
        display: flex;
        position: relative;
        justify-content: center;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    #logo {
        margin: auto;
        margin-left: 1rem;
        img {
            height: 40px;
        }
    }

    #pages {
        margin: auto;
        margin-right: 1rem;
        user-select: none;
        display: flex;
        
        a {
            cursor: pointer;
            font-size: 14px;
            padding: 6px 16px;
            border-radius: 16px;
            background-color: transparent;
            border-color: transparent;
            transition: all .3s ease;
            &:hover,
            &:active,
            &:focus {
                border-color: #000;
                background-color: #000;
                color: #fff;
            }
            strong {
                font-weight: 600;                
            }
        }
    }

    .checkbox {
        display: none;
    }

    .hamburger-lines {
        display: none;
    }
}

#lang {
    line-height: 40px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}

@media only screen and (max-width: 991px) {
    .header-menu {
        flex-direction: column;
    
        #pages {
            margin-bottom: 1.2rem;
            font-size: 1.5rem;
            border-radius: 4px;
            font-weight: 500;

            a {
                margin-left: 0;
            }
        }

        &.opened {
            .hamburger-lines .line1 {
                transform: rotate(45deg);
              }

            .hamburger-lines .line2 {
                transform: scaleY(0);
            }
            
            .hamburger-lines .line3 {
                transform: rotate(-45deg);
            }
    
            #pages {
                height: 200px;
                visibility: visible;
                transition: height 0.25s ease-in-out, visibility 0.35s cubic-bezier(1, -0.02, 1, 0.11);
            }
        }

        &.closed {
            #pages {
                height: 0;
                visibility: hidden;
                transition: height 0.25s ease-in-out, visibility 0.35s cubic-bezier(1, -0.02, 1, 0.11);
                margin: 0;
                padding: 0;
                a {
                    display: none;
                }
            }
        }

        .checkbox {
            position: absolute;
            display: block;
            height: 32px;
            width: 32px;
            top: 10px;
            right: 17px;
            z-index: 999;
            opacity: 0;
            cursor: pointer;
        }
          

        #pages {
            margin-top: 1rem;
            box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
            width: 100%;
            display: flex;
            visibility: hidden;
            flex-direction: column;
            height: 0;
            transition: transform 0.5s ease-in-out;
            text-align: center;
            z-index: 999;
            gap: 1rem;
            .link {
                background-color: #F2F2F2cc;
                padding: 8px 0;
            }
        }

        .hamburger-lines {
            display: block;
            height: 26px;
            width: 32px;
            position: absolute;
            top: 14px;
            right: 20px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .line {
                display: block;
                height: 4px;
                width: 100%;
                border-radius: 10px;
                background: #0e2431;
              }


            .line1 {
                transform-origin: 0% 0%;
                transition: transform 0.4s ease-in-out;
            }
            
            .line2 {
                transition: transform 0.2s ease-in-out; 
            }
            
            .line3 {
                transform-origin: 0% 100%;
                transition: transform 0.4s ease-in-out;
            }
          }     
    }
}